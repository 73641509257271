import React from 'react';
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { LinkContainer } from 'react-router-bootstrap';
import Search from "./Search";

export default () => (
    <div>
        <Jumbotron>
            <Container>
                <h1>Search for Dodo Codes or post your own</h1>
                <LinkContainer to="/new">
                    <Button variant="success mt-2">Post your Dodo Code</Button>
                </LinkContainer>
            </Container>
        </Jumbotron>
        <Container>
            <Search />
        </Container>
    </div>
)
