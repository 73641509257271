export const resourceOptions = [
    { name: "Apples", icon: "🍎", value: "apples"},
    { name: "Oranges", icon: "🍊", value: "oranges"},
    { name: "Cherries", icon: "🍒", value: "cherries"},
    { name: "Peaches", icon: "🍑", value: "peaches"},
    { name: "Pears", icon: "🍐", value: "pears"},
    { name: "Coconuts", icon: "🥥", value: "coconuts"},
    { name: "Iron Nuggets", icon: "🔗", value: "iron_nuggets"},
    { name: "Tools", icon: "🔧", value: "tools"},
    { name: "Friendship", icon: "🎉", value: "friendship"},
];
export const resourceOptionsByValue = {};
resourceOptions.forEach(opt => {
    opt.label = opt.name + ' ' + opt.icon;
    resourceOptionsByValue[opt.value] = opt;
});
