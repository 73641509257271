import React, {useState} from "react";
import moment from "moment";
import _ from "lodash/function";
import { serviceRequest } from "./service";


export const SearchContext = React.createContext(null);

const searchWithoutDebounce = (filter = {}, setResults, setUpdatedOn, setSearchInProgress) => {
    const params = {};
    if(filter.lookingFor) {
        params.lookingFor = filter.lookingFor.map(x => x.value).join(',');
    }
    if(filter.offering) {
        params.offering = filter.offering.map(x => x.value).join(',');
    }
    return serviceRequest('/search', {
        params,
    }).then(response => {
        setResults(response.results);
        setUpdatedOn(response.updatedOn);
    }).finally(() => {
        setSearchInProgress(false);
    })
};
const search = _.debounce(searchWithoutDebounce, 1000);


const initialNow = moment().format();
export const SearchStateProvider = (props) => {
    const [ lookingFor, setLookingFor ] = useState([]);
    const [ offering, setOffering ] = useState([]);
    const [ initialized, setInitialized ] = useState(false);
    const [ searchInProgress, setSearchInProgress ] = useState(false);
    const [ results, setResults ] = useState([]);
    const [ updatedOn, setUpdatedOn ] = useState(initialNow);
    const hasFilter = lookingFor.length > 0 || offering.length > 0;

    const filter = { lookingFor, offering };
    const onSearch = React.useCallback(() => {
        setSearchInProgress(true);
        search(
            filter,
            setResults,
            setUpdatedOn,
            setSearchInProgress,
        );
    }, [filter, setResults, setUpdatedOn, setSearchInProgress]);

    React.useEffect(() => {
        if(initialized) {
            return;
        }
        searchWithoutDebounce(
            filter,
            setResults,
            setUpdatedOn,
            setSearchInProgress,
        ).finally(() => setInitialized(true))
    }, [filter, setResults, setUpdatedOn, setSearchInProgress, initialized]);

    const contextValue = {
        lookingFor,
        setLookingFor,
        offering,
        setOffering,
        searchInProgress,
        results,
        updatedOn,
        initialized,
        hasFilter,
        search: onSearch,
    };
    return (
        <SearchContext.Provider value={contextValue}>
            {props.children}
        </SearchContext.Provider>
    )
};
