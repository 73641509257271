const baseUrl = (
    process.env.REACT_APP_SERVICE_URL || 'http://localhost:5000'
).replace(/\/$/, '');

export const serviceRequest = async (url, opts = {}) => {
    url = baseUrl + '/' + url.replace(/^\//, '');
    url = new URL(url);

    const params = opts.params;
    opts.params = undefined;

    if (params) {
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    }

    const response = await fetch(url, opts);
    return await response.json();
};
