import React from 'react';
import MultiSelect from "react-multi-select-component";
import {resourceOptions} from "./resources";


const ResourceSelect = (props) => (
    <MultiSelect
        options={resourceOptions}
        value={props.value}
        onChange={props.onChange}
        hasSelectAll={false}
        disableSearch={true}
        overrideStrings={{
            allItemsAreSelected: 'Everything',
            selectAll: 'Everything',
        }}
    />
);
export default ResourceSelect;
