import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Moment from 'react-moment';
import './Search.css';
import ResourceSelect from "./ResourceSelect";
import { resourceOptionsByValue } from "./resources";
import { SearchContext } from "./searchState";
import Spinner from "react-bootstrap/Spinner";

const Search = () => {
    const searchState = React.useContext(SearchContext);
    return (
        <Row>
            <Col md={3}>
                <Form className="Search-form">
                    <p><strong>Filter results</strong></p>
                    <hr/>
                    <Form.Group controlId="looking-for">
                        <Form.Label>
                            I'm looking for:
                        </Form.Label>
                        <ResourceSelect
                            value={searchState.lookingFor}
                            onChange={(val) => {
                                searchState.setLookingFor(val);
                                searchState.search();
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="offering">
                        <Form.Label>
                            I'm offering:
                        </Form.Label>
                        <ResourceSelect
                            value={searchState.offering}
                            onChange={(val) => {
                                searchState.setOffering(val);
                                searchState.search();
                            }}
                        />
                    </Form.Group>
                    <Button
                        variant="outline-primary"
                        disabled={searchState.searchInProgress}
                        onClick={searchState.search}
                    >
                        Refresh{' '}
                        {searchState.searchInProgress && (
                            <Spinner animation="border" size="sm" />
                        )}
                    </Button>
                </Form>
            </Col>
            <Col md={9}>
                <div className="Search-results">
                    <Row>
                        <Col xs={10}>
                            <h2>
                                {searchState.hasFilter ? (
                                    'Search results: '
                                ) : (
                                    'Latest islands: '
                                )}
                            </h2>
                        </Col>
                        <Col xs={2} className="text-right">
                            {searchState.searchInProgress && (
                                <div style={{}}>
                                    <Spinner animation="border"  />
                                </div>
                            )}
                        </Col>
                    </Row>
                    {/*
                    <div>
                        {searchState.initialized && (
                            <small className="text-muted">
                                Updated <Moment fromNow>{searchState.updatedOn}</Moment>
                            </small>
                        )}
                    </div>
                    */}
                    <div>
                        {searchState.results.map(result => (
                            <SearchResult key={result.id} result={result} />
                        ))}
                    </div>
                </div>
            </Col>
        </Row>
    )
};

const SearchResult = (props) => {
    const result = props.result;
    return (
        <Card className="SearchResult my-4">
            <Card.Body>
                <Container fluid>
                    <Row>
                        <Col sm={9}>
                            <Card.Title>{result.islandName}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">{result.userName}</Card.Subtitle>
                            <Card.Text>
                                {result.description}
                            </Card.Text>
                            <Card.Text>
                                <strong className="text-muted">Offering:</strong> {
                                result.offering.map(opt => (
                                    (resourceOptionsByValue[opt] || {}).label
                                )).join(', ')
                                }<br/>
                                <strong className="text-muted">Looking for:</strong> {
                                result.offering.map(opt => (
                                    (resourceOptionsByValue[opt] || {}).label
                                )).join(', ')
                                }<br/>
                                <small className="text-muted">
                                    Updated <Moment fromNow>{result.timestamp}</Moment>
                                </small>
                            </Card.Text>
                        </Col>
                        <Col sm={3} className="text-center">
                            <div className="SearchResults-visitorsHeader text-muted">
                                <small>Visitors currently</small>
                            </div>
                            <div className="SearchResult-visitors">
                                <span className="SearchResult-visitorsCurrent">
                                    {result.currentVisitors}
                                </span>
                                <span className="SearchResult-visitorsDivider">
                                    /
                                </span>
                                <span className="SearchResult-visitorsMax">
                                    {result.maxVisitors}
                                </span>
                            </div>
                            {result.currentVisitors < result.maxVisitors && (
                                <Button variant="primary" block>
                                    Visit island!
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
};
export default Search;
