import React from 'react';
import './App.css';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import PageIndex from "./PageIndex";
import PageSearch from "./PageSearch";
import PageFeedback from "./PageFeedback";
import PageNew from "./PageNew";
import PageAbout from "./PageAbout";
import { SearchStateProvider } from "./searchState";

function App() {
    return (
        <Router>
            <SearchStateProvider>
                <div className="App">
                    <AppNav />
                    <Switch>
                        <Route exact path="/">
                            <PageIndex />
                        </Route>
                        <Route path="/search">
                            <PageSearch />
                        </Route>
                        <Route path="/about">
                            <PageAbout />
                        </Route>
                        <Route path="/new">
                            <PageNew />
                        </Route>
                        <Route path="/feedback">
                            <PageFeedback />
                        </Route>
                        <Route path="*">
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </div>
            </SearchStateProvider>
        </Router>
    );
}

const AppNav = () => (
    <Navbar bg="dark" variant="dark">
        <LinkContainer to="/">
            <Navbar.Brand>Dodoshare</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <LinkContainer to="/search">
                    <Nav.Link>Search islands</Nav.Link>
                </LinkContainer>
                <NavDropdown title="Other stuff" id="basic-nav-dropdown">
                    <LinkContainer to="/about">
                        <NavDropdown.Item>About Dodoshare</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/feedback">
                        <NavDropdown.Item>Give feedback</NavDropdown.Item>
                    </LinkContainer>
                </NavDropdown>
            </Nav>
            <Form inline>
                <LinkContainer to="/new">
                    <Button variant="outline-light">Post your Dodo Code</Button>
                </LinkContainer>
            </Form>
        </Navbar.Collapse>
    </Navbar>
);

export default App;
